@import '../../resources//styles/scss/common/common';

nav.custom_navbar {
    padding: 15px 10px;

    a {
        img {
            height: 33px;
            width: 176px;
        }
    }

    ul {
        li {
            a {
                font-size: rem(20);
                font-family: $circularStd-Bold;
                margin: 0 rem(35);
            }
        }
    }
}