@import './scss/common/common';

.gc-table {
  width: 100%;
  position: relative;
  padding: 0;

  .center-loading {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;

    img {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
  }

  &.loading {
    > div {
      opacity: 0.3;
    }
  }

  .rdt_TableHead {
    .rdt_TableHeadRow {
      .rdt_TableCol {
        padding: 0;
        color: $black;
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      font-family: $circularStd-Book;
      font-size: rem(18);
      background: $white;
      max-height: 240px;
      height: 100%;
      color: $black;
      min-height: 81px;
      // padding: rem(11) 0;
      padding-left: rem(24);
      border-top: 1px solid rgba(190, 184, 184, 0.12);
      border-bottom: 1px solid rgba(190, 184, 184, 0.12);

      &:nth-child(even) {
        background-color: $tableGray;
      }

      .rdt_TableCell {
        padding: rem(11) 0 !important;
      }
    }
  }

  .rdt_TableHeadRow {
    font-size: rem(18);
    font-family: $circularStd-Bold;
    border-bottom: 2px solid $black;
    width: 100%;
  }
}

.rdt_TableHeadRow {
  padding-left: rem(24);
}

.table-avatar {
  $avatarSize: 57px;
  height: $avatarSize;
  width: $avatarSize;
  max-width: $avatarSize;
  min-width: $avatarSize;
  background: $tableAvatar;
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar-img {
    height: $avatarSize;
    width: $avatarSize;
    overflow: hidden;
  }
}

.order-display {
  // margin: 8px 10px;
  background: gray;
  width: 284px;
  height: 62px;
}

//Table avatar icon css

.multipleDataCell {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .table-avatar {
    $avatarSize: 57px;
    height: $avatarSize;
    width: $avatarSize;
    max-width: $avatarSize;
    min-width: $avatarSize;
    background: $tableAvatar;
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar-img {
      height: $avatarSize;
      width: $avatarSize;
    }
  }

  .multipleCelle-text {
    padding: 0 rem(20) 0 rem(16);
  }

  p {
    margin-bottom: 0;
  }
}

//Paragraph text in table
.tableText {
  margin: 0 auto;
  overflow: hidden;
  width: 510px;
  padding: rem(6);
}

.table-p {
  font-size: rem(18);
  font-family: $circularStd-Book;
  margin-bottom: 0;
}

//user order-page and order-page-one column  customization
.table_btn_group {
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 10px;

  .group_text {
    font-size: rem(16);
    font-family: $circularStd-Book;
    width: 100%;
    min-width: 75px;
    max-width: 78px;
    margin-bottom: 0;
    margin-right: auto;
    padding-right: rem(10);
  }

  .theme-btn {
    height: 62px;
    max-width: 217px;
    width: 100%;
  }
}
