@import '../../../resources/styles/scss/common/common';

.theme-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  font: {
    size: rem(18);
    family: $circularStd-Bold;
  }

  padding: 0 rem(20);
  text-align: center;
  min-height: 44px;
  min-width: 164px;
  transition: 0.5s ease all;
  position: relative;

  &.btn--outline {
    border: 1px solid;
    background-color: transparent;
    transition: 0.3s ease all;

    &.btn--black {
      border-color: $black;
      color: $black;

      &:hover {
        background-color: $black;
        color: $white;
      }
    }

    &.btn--white {
      border-color: $white;
      color: $white;

      &:hover {
        background-color: $white;
        color: $black;
      }
    }

    &.btn--blue {
      border-color: #2366ff;
      color: $black;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.btn--solid {
    border: 1px solid;
    background-color: transparent;
    transition: 0.3s ease all;

    &.btn--black {
      border-color: $black;
      background-color: $black;
      color: $white;

      &:hover {
        opacity: 0.8;
      }
    }

    &.btn--white {
      border-color: $white;
      background-color: $white;
      color: $black;

      &:hover {
        opacity: 0.8;
      }
    }

    &.btn--blue {
      border-color: #2366ff;
      background-color: #2366ff;
      color: $white;

      &:hover {
        opacity: 0.8;
      }
    }

    &.btn--disabled {
      border-color: #f1f1f1;
      background-color: #f1f1f1;
      color: #d1d1d1;
    }
  }

  &.is-rounded {
    border-radius: 7px;
  }

  &.roundedBig {
    border-radius: 50px;
  }

  &.processing {
    span {
      opacity: 0;
      visibility: hidden;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

//162 | 42
//180 | 44
//164 | 42
//217 |62
//404 |92
