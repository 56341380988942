@import '../../resources/styles/scss/common/variables';

.mobile-landing {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-self: center;

    .text-banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .text {
            h1 {
                text-align: center;
                font-family: $circularStd-Bold;

                span {
                    color: $blue;
                }
            }


        }
    }
}