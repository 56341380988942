@import 'src/resources/styles/scss/common/common';

.custom_login_form {
  input {
    border-radius: 0;
    border: none;
    font-size: rem(15);
    font-family: $HKGrotesk-Regular;

    &:focus {
      border-left: 4px solid $darkBlue;
      box-shadow: none;
    }

    &.hasError {
      border-bottom: 2px solid #cc0101;
    }
  }

  label {
    font-size: rem(11);
    font-family: $HKGrotesk-Regular;
  }

  .error-msg {
    color: #cc0101;
  }
}
