@font-face {
    font-family: 'CircularStd-Book';
    src: url('../../fonts/CircularStd-Book.eot');
    src: url('../../fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CircularStd-Book.woff2') format('woff2'),
        url('../../fonts/CircularStd-Book.woff') format('woff'),
        url('../../fonts/CircularStd-Book.ttf') format('truetype'),
        url('../../fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd-Bold';
    src: url('../../fonts/CircularStd-Bold.eot');
    src: url('../../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CircularStd-Bold.woff2') format('woff2'),
        url('../../fonts/CircularStd-Bold.woff') format('woff'),
        url('../../fonts/CircularStd-Bold.ttf') format('truetype'),
        url('../../fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BrandonGrotesque-Light';
    src: url('../../fonts/BrandonGrotesque-Light.eot');
    src: url('../../fonts/BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/BrandonGrotesque-Light.woff2') format('woff2'),
        url('../../fonts/BrandonGrotesque-Light.woff') format('woff'),
        url('../../fonts/BrandonGrotesque-Light.ttf') format('truetype'),
        url('../../fonts/BrandonGrotesque-Light.svg#BrandonGrotesque-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HKGrotesk-Regular';
    src: url('../../fonts/HKGrotesk-Regular.eot');
    src: url('../../fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HKGrotesk-Regular.woff2') format('woff2'),
        url('../../fonts/HKGrotesk-Regular.woff') format('woff'),
        url('../../fonts/HKGrotesk-Regular.ttf') format('truetype'),
        url('../../fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}