@import "../../../resources/styles/scss/common/common";
$dimension: 39px;

.user-card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-avatar {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding-right: rem(25);

        .avatar {
            background: $black;
            height: $dimension;
            flex: 0 0 $dimension;
            max-width: $dimension;
        }

        h5 {
            font-size: rem(15);
            font-family: $circularStd-Bold;
            margin-left: rem(20);
            padding-right: rem(20);
            margin-bottom: 0;
            flex: 0 0 calc(100% - $dimension);
            max-width: calc(100% - $dimension);
        }
    }

    .logout {
        cursor: pointer;
    }
}