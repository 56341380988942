@import '../../../resources/styles/scss/common/common';
$dimension: 39px;
.user_account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 236px;

    button {
        border: none;
        margin: 0 rem(5);
        background: transparent;
        color: $white;
    }

    .user-avatar {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding-right: rem(25);

        .avatar {
            height: $dimension;
            flex: 0 0 $dimension;
            max-width: $dimension;
        }

        h5 {
            color: $white;
            font-size: rem(15);
            font-family: $circularStd-Bold;
            margin-left: rem(20);
            padding-right: rem(20);
            margin-bottom: 0;
            flex: 0 0 calc(100% - $dimension);
            max-width: calc(100% - $dimension);
        }
    }
}