//@import './fonts/font';
///* import bootstrap to set changes */
//@import "~bootstrap/scss/bootstrap";
@import '../styles/scss/common/common';
@import './table';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

.App {
  height: 100vh;
}

img {
  max-width: 100%;
}

h1 {
  font: {
    font-size: rem(35);
    family: $circularStd-Book;
  }

  strong {
    font-family: $circularStd-Bold;
  }
}

h2 {
  font: {
    size: rem(30);
    family: $circularStd-Book;
  }

  strong {
    font-family: $circularStd-Bold;
  }
}

h3 {
  font: {
    size: rem(24);
    family: $circularStd-Book;
  }

  strong {
    font-family: $circularStd-Bold;
  }
}

h4 {
  font: {
    size: rem(22);
    family: $circularStd-Book;
  }

  strong {
    font-family: $circularStd-Bold;
  }
}

h5 {
  font: {
    size: rem(20);
    family: $circularStd-Book;
  }

  strong {
    font-family: $circularStd-Bold;
  }
}

h6 {
  font: {
    size: rem(18);
    family: $circularStd-Book;
  }

  strong {
    font-family: $circularStd-Bold;
  }
}

p {
  font: {
    size: rem(16);
    family: $circularStd-Book;
  }

  strong {
    font-family: $circularStd-Bold;
  }
}

button,
input,
textarea {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.section-title {
  text-align: center;
  padding: rem(82) 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: rem(20);
  }

  p {
    margin-bottom: 0;
  }
}

// page sidebar + section

.side_navbar {
  +section {
    flex-grow: 1;
  }
}

//Page title + search bar
//page top content title, searchbar and button
.page_top_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(20);
}



//page top content title form 
.page_top_content_form {
  @extend .page_top_content;
  padding: rem(30) rem(42);
}

button {

  &:hover,
  &:focus {
    box-shadow: none;
  }
}

.page_container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;

  &::-webkit-scrollbar {
    width: 0;
  }

  .page-container-box {
    padding: 0 rem(40);
  }
}

.customer-section {
  .page_container {
    .page-container-box {
      padding: 0 rem(128);
    }
  }
}

@media screen and (max-width:1368px) {
  .customer-section {
    .page_container {
      .page-container-box {
        padding: 0 rem(66);
      }
    }
  }
}

@media screen and (max-width:1024px) {
  .customer-section {
    .page_container {
      .page-container-box {
        padding: 0 rem(40);
      }
    }
  }
}






.react-select-container {
  border-radius: 0;

  .react-select__control {
    padding: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;


    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      border-color: transparent;
    }
  }

  .react-select__menu {
    border-radius: 0;
    z-index: 2;

    .react-select__menu-list {
      padding: 0;

      .react-select__option {
        color: $black;
      }
    }
  }
}



// 

.form_description {
  margin-top: rem(26);
  max-width: 854px;
  color: $black;
}

//This class is used in all the pages that contain table
.page-container-box {
  width: 100%;
  overflow: hidden;
}

//These css are used on form
.form_panel {
  padding-left: rem(40);
}

//These classname are frequently used in pages
.gc-action-bar {
  margin-bottom: rem(40);
}

.page_top_frame {
  //padding: 0 rem(40);
  display: flex;
  justify-content: space-between;

  >.row {
    flex-grow: 1;
  }

  +.page_top_frame {
    margin-top: rem(40);
  }
}

// .center-loading {
//   position: absolute;
//   width: 100%;
//   top: 0;
//   left: 0;
//   z-index: 1;
//   height: 100%;

//   img {
//     position: absolute;
//     width: 60px;
//     height: 60px;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 5;
//   }
// }

//Global css for page to content