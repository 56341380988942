@import '../../resources/styles/scss/common/common';
@import "../Login/login.scss";

.forget-section {
    @extend .login-section;

    .forget-section-container {
        @extend .login-section-container;

        .forget-form-wrapper {
            @extend .form-wrapper
        }

        .forget-form {
            @extend .login-form;

            .forget-field-wrapper {
                @extend .form-wrapper;

            }

            .forget-button-wrapper {
                @extend .button-wrapper;
                margin-top: rem(60);
            }
        }

        .forget-link {
            @extend .link;
        }
    }
}