//Font family variables

$circularStd-Book: 'CircularStd-Book';
$circularStd-Bold: 'CircularStd-Bold';
$BrandonGrotesque-Light: 'BrandonGrotesque-Light';
$HKGrotesk-Regular: 'HKGrotesk-Regular';

// Theme Colors Variables
$black: #000;
$black1: #4a4a4a;
$white: #fff;
$gray-100: #f0f0f1;
$blue: #2366ff;
$darkBlue: #0027ff;
$orange: #f39a88;
$tableGray: rgba(239, 239, 239, 0.3);
$danger: #cc0000;
$tableAvatar: #ccc;
$borderGray: #c3c3c3;
$paginationBorder: #dee2e6;
