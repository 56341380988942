* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;

}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  margin: 0;
  transition: 0.3s ease all;
  scroll-behavior: smooth;

  font : {
    size: rem(16);
    font-family: $circularStd-Book;
  }
}

a,
a * {
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}