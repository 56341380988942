main.main {
    height: 100vh;
    overflow: hidden;

    .page_wrapper {
        display: flex;
        height: calc(100% - 64px);

        .side_navbar {
            height: 100%;
            flex: 0 0 220px;
            max-width: 220px;

            .page_container {
                flex-grow: 1;
                width: 100%;
                padding: 0;
                height: 100%;
                overflow-y: auto;
            }
        }
    }
}