@import "src/resources/styles/scss/common/common";

.login-section {
  background: $blue;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(30);
  overflow: hidden !important;

  .login-section-container {
    .form-wrapper {
      .logo {
        margin-bottom: rem(96);
      }

      .login-form {

        .form-floating {
          >.form-control {

            &:focus,
            .form-select,
            &:not(:placeholder-shown) {
              ~label {
                transform: translateY(calc(-50% - 20px));
              }
            }
          }

          input {
            font-size: rem(15);
            font-family: $HKGrotesk-Regular;
            padding: 0 rem(23);
            height: 72px;
            border-left: 4px solid transparent;
            color: $black;

            &:focus {
              border-color: $darkBlue;
            }
          }

          label {
            text-transform: capitalize;
            font-size: rem(15);
            font-family: $HKGrotesk-Regular;
            padding: 0 rem(23);
            transform: translateY(-50%);
            height: auto;
            top: 50%;
          }

          &.password {
            input {
              padding-right: rem(100);
            }
          }
        }

        .field-wrapper {
          position: relative;

          .forget-password {
            position: absolute;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.30);
            font-family: $HKGrotesk-Regular;
            font-size: rem(11);
            top: 36px;
            transform: translateY(-50%);
            right: rem(23);
          }

          +.field-wrapper {
            margin-top: rem(20);
          }
        }

        .button-wrapper {
          margin-top: rem(60);

          .theme-btn {
            width: 100%;
            min-height: 92px;
            font-size: rem(22);
          }
        }
      }

      .link {
        text-align: center;
        margin-top: rem(50);

        a {
          font: {
            size: rem(17);
            family: $circularStd-Book;
          }

          color: $white;
        }
      }
    }
  }
}