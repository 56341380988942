@import "../../resources/styles/scss/common/common";

.sideNav {
    // border-right: 1px solid rgb(193, 190, 190);
    box-shadow: 3px 3px 6px -3px rgba(0, 0, 0, 0.16);
    margin-right: 2px;
    flex: 0 0 220px;
    max-width: 220px;
    padding: rem(20);

    .menu {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ul {
            height: 100%;
            display: flex;
            justify-content: center;
            list-style: none;

            li {
                text-align: left;

                a {
                    text-decoration: none;
                    text-align: left;
                    font-size: rem(22);
                    color: $black;
                    font-family: $circularStd-Book;
                    padding-bottom: 6px;
                    border-bottom: 2px solid transparent;
                    position: relative;
                    transition: 0.3s ease all;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 0;
                        height: 2px;
                        background: $black;
                        left: 0;
                        transition: 0.3s ease all;
                    }

                    &.active {
                        font-family: $circularStd-Bold;

                        &::after {
                            width: 100%;
                        }
                    }
                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }
                }

                + li {
                    margin-top: rem(22);
                }
            }
        }
    }
}